import React, { ReactElement } from "react";
import { usePromiseTracker } from "react-promise-tracker";
// import Loader from "react-loader-spinner";

interface SpinnerProp {
  area?: string;
  showLoader?: boolean;
  color?: string;
  altText?: string;
  width?: number;
  height?: number;
  type?: "TailSpin" | "ThreeDots";
  children?: ReactElement | ReactElement[];
}
const Spinner = ({
  area,
  showLoader,
  color,
  altText,
  width,
  height,
  type,
  children,
}: SpinnerProp): ReactElement => {
  const { promiseInProgress } = usePromiseTracker({ area, delay: 100 });

  if (!promiseInProgress && !showLoader && children) return <>{children}</>;
  if (!promiseInProgress && !showLoader) return <div>{altText}</div>;

  return (
    <div className="spinner">
      {/* <Loader
        type={type || "ThreeDots"}
        color={color || "#b20e23"}
        height={height || 25}
        width={width || 100}
      /> */}
    </div>
  );
};
export default Spinner;
